<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
import {selectWorkDay7ProducitonCount} from '../../../../../libs/axios';
export default {
  data () {
    return {
      cdata:"",
    }
  },
  methods: {
     init() {
      this.getDay7WageEchartsList();
    },
   getDay7WageEchartsList() {
      this.loading = true;
        var params2 = window.location.href.split('?');
    var dz_url2 = params2[1].split('&');
      selectWorkDay7ProducitonCount(dz_url2[0].split("=")[1]).then((res) => {
        // vm.$store.commit("setMessageType", res.result);
        //  alert(JSON.stringify(res.data));
        this.loading = false;
          this.cdata=res.data;
      });
    },
  },
  components: {
    Chart,
  },
  mounted () {
    this.init();
    setInterval(this.init,120000);
  },
}
</script>

<style lang="scss" scoped>
</style>