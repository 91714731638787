<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
       <!-- <div  v-if="!loading&&showdiv=='0'" class="host-body"> -->
      <div  v-if="!loading" class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 style="width:23.3%;height:.0625rem;" />
          <div class="d-flex jc-center"  style="width:53.3%;" >
            <dv-decoration-8 :color="['#568aea', '#000000']" style="width:1.5rem;height:.625rem;" />
            <div class="title" style="width: 10rem;">
              <span class="title-text" style="font-size:44px;position: initial;">智能制造互联网工业云</span>
              <dv-decoration-6
                class="title-bototm"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
                style="width:3.125rem;height:.1rem;"
              />
              <div style="    font-size: xx-large;
    margin-top: 0.8rem;
    position: absolute;
    color: red;
    margin-left: 30%;
    font-weight: 800;">{{workName}}</div>
            </div>
            <dv-decoration-8
              :reverse="true"
              :color="['#568aea', '#000000']"
              style="width:1.5rem;height:.625rem;"
            />
          </div>
          <dv-decoration-10 style="width:23.3%;height:.0625rem; transform: rotateY(180deg);" />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex" style="width: 40%">
           
               <div class="react-left bg-color-blue mr-3">
             <span class="text fw-b" style="font-size: 40px;color: rgb(255, 193, 7);">河南旭升帽业有限公司</span>
           
            </div>
           
          </div>
          <div style="width: 40%" class="d-flex">
            <div
              class="react-right ml-4"
              style="width: 6.25rem; text-align: left;background-color: #0f1325;line-height: 1rem;"
            >
              <span class="text fw-b"  style="font-size:30px;margin-left: 25px;" >{{dateYear}} {{dateWeek}} {{dateDay}}</span>
               </div>
           
          </div>
        </div>

        <div class="body-box">
           
          <!-- 第三行数据 -->
          <div class="center-box">
              <dv-border-box-12 style="height: 4.125rem;">
                 <centerTopRight />
              </dv-border-box-12>
          </div>
          <div class="bototm-box" style="height: 5.125rem;">
            <dv-border-box-12>
              <bottomRightPro/>
            </dv-border-box-12>
          </div>
        </div>
      </div>
      
    </dv-full-screen-container>
  </div>
</template>

<script>
import {} from '../libs/axios';
import { formatTime } from '../utils/index.js'
import centerTopRight from "./workbigview/centerTopRight";
import bottomRightPro from "./workbigview/bottomRightPro";

 
//  alert(getStore("id"));
export default {
  data () {
    return {
       selectList: [], // 多选数据
      loading: true,
      workName:"",
      showdiv:"0",
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
        // 添加或编辑表单对象初始化数据
        lineGuids: "",
        workShopName:"",
    };
  },
  components: {
    centerTopRight,
    bottomRightPro,
  },
  mounted () {
    this.init();
    this.timeFn();
    this.cancelLoading();
  },
  methods: {
     init() {
        var params1 = window.location.href.split('?');
    var dz_url1 = params1[1].split('&');
    this.workName=decodeURIComponent(dz_url1[1].split("=")[1]);
    },
    timeFn () {
      setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss');
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd');
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000)
    },
    cancelLoading () {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  
   
  }
};
</script>

<style lang="scss">
@import './workbigview/index.scss';
</style>